  .sidebar {
    width: 20vw;
    transition: all 0.3s ease-in-out;
    ul {
      list-style: none;
      padding: 0;
    }

    ul li {
      margin-bottom: 15px;
      font-size: 16px;
    }

    li:first-child {
      margin-top: 5vh;
    }

    ul li a {
      text-decoration: none;
      color: #333;
    }

    ul li a:hover,
    ul li.active a {
      color: #f32f1e;
      font-weight: 800;
    }

    .logout {
      margin-top: 30px;
      font-weight: 600;
      color: #333;
      cursor: pointer;
    }

    &.open {
      width: 100vw;
      position: absolute;
      background-color: white;
      z-index: 10;
    }
  }

  .navToggle {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .personalInfo {
    width: 60vw;
    padding: 3vh;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
    background: linear-gradient(to right, rgba(239, 239, 239, 0.3), white, white, white);

    h2 {
      font-weight: 800;
      margin-bottom: 20px;
      color: #333;
      font-size: 24px;
    }

  }


  @media (max-width: 768px) {

    .sidebar {
      width: 100vw;
      position: absolute;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: white;
      z-index: 10;
      transform: translateX(-100%);
      &.open {
        transform: translateX(0);
      }

      .navToggle {
        display: block;
      }
    }

    .personalInfo {
      width: 100%;
      padding: 0;
    }
  }

.editButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    padding: 10px 15px;
    background-color: #bc2832;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;

  }
}

@import "../../styling/Animations.scss";

.signup {
  box-sizing: border-box;
  // background: gray;
  display: flex;
  justify-content: center;
  padding: 10vh;
  width: 100vw;
  height: auto;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.signup__form__title {
  font-family: "Gilroy-Black";
  font-size: 20px;
  color: black;
}

.signup__form {
  height: 50vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.558);

}

.signup__form__input {
  box-sizing: border-box;
  border: none !important;
  box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.551);
  border-radius: 5px;
  width: 20vw;
  padding: 0.5vw;
  font-size: 17px;
  height: auto;
}

.signup__form__btn {
  background-color: black;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background-color: #ececec;
    color: black;
  }
}

.signup__form__login {
  font-family: "Gilroy-Black";
}

@media (max-width: 768px) {
  .signup {
    box-sizing: border-box;
    // background: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh;
    width: 100%;
    // position: absolute;
  }

  .signup__form {
    min-height: 75vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.558);
  }
  .signup__form__input {
    box-sizing: border-box;
    width: 70vw;
    padding: 1vw;
    height: 5vh;
    margin-bottom: 2vh;
    padding-left: 15px;
  }

  .signup__form__title {
    font-family: "Gilroy-SemiBold";
    font-size: 21px;
    margin-bottom: 20px;
  }
}

.signup__form__password_container {
  position: relative;
  display: flex;
  align-items: center;
}

.signup__form__input {
  padding-right: 2.5rem;  // Göz ikonuna yer açmak için sağdan boşluk bırakılır
}

.signup__form__password_toggle {
  position: absolute;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  @media (max-width: 768px) {
    margin-bottom: 2vh;
  }
}

