.timer {
  font-style: normal;
  box-sizing: border-box;
  width: 80%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: start;
  letter-spacing: 0.5px;

  p {
    margin: 0 5px 0 2px
  }

  span {
    display: flex;
    align-items: center;

    b {
      font-family: Gilroy-Black;
      color: white;
      background: #afafaf;
      border-radius: 45%;
      padding: 3px 9px;
      margin-right: 3px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      font-size: 2rem;

        @media (max-width: 1024px) {
            font-size: 1.5rem;
            padding: 2px 6px;
        }

      @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 2px 6px;
      }
    }
  }
}

.container {
  font-family: Gilroy-Regular;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.5rem;
  margin-bottom: 1%;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}
