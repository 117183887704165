.changePassword {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.changePasswordForm {
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  //background: linear-gradient(to right, rgba(239, 239, 239, 0.3), white, white, white);

  @media (max-width: 768px) {
    width: 80%;
    padding: 2vh;
  }

  .changePasswordInput {
    box-sizing: border-box;
    border: none !important;
    box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.551);
    border-radius: 5px;
    width: 20vw;
    padding: 0.5vw;
    font-size: 17px;
    height: auto;
    margin-bottom: 20px;

    &:first-child {
      margin-top: 20px;
    }

    @media (max-width: 768px) {
      width: 70vw;
      padding: 1vw;
      height: 5vh;
      margin-bottom: 2vh;
      padding-left: 15px;
    }
  }

  .changePasswordButtons {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      padding: 10px 15px;
      background-color: #bc2832;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      //margin-left: 10px;
      transition: background-color 0.3s ease;

    }
  }
}