@import "../styling/Animations.scss";

.auction_details {
  box-sizing: border-box;
  // background-color: rgb(174, 12, 12);
  color: black;
  height: auto;
  max-width: 100vw;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.auction_details__header {
  margin-top: 5vh;
  width: 100vw;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auction_details__header__text {
  font-size: 18px;
  margin-bottom: 0.4vh;
}

.bold {
  letter-spacing: 1px;
  font-family: "Gilroy-SemiBold";
}

.form {
  margin-top: 0.4dvh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.4dvh;
  gap: 1vw;
  width: 40vw;

  @media (max-width: 900px) {
    width: 80dvw;
  }
}

.form_inner {
  border: 2px solid #ededed9d;
  border-radius: 20px;
  transition: 300ms;
  padding: 10px;
  box-sizing: border-box;
  width: 80%;


  &:focus {
    //background-color: white;
    box-shadow: 0 0 2px 2px gray;
  }
}

::placeholder {
  color: black;
}

.form__text_inp {
  width: 100%;
  max-width: 500px;
  outline: none;
  //background-color: #ededed9d;

  //padding: 10px;
  box-sizing: border-box;
  border: none !important;
  background-color: transparent;

  max-height: 200px;
  //overflow-y: scroll;

  select {
    color: #bc2832;
  }
}

.toggle_btn {
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  outline: none;
  letter-spacing: 0.5px;
  background: transparent;
  border: none;
  //padding: 10px;
  //padding-left: 10px;
  font-family: "Gilroy-Light";
  box-sizing: border-box;
}

.auction_product_container {
  width: 80vw;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, calc(78.5 / 4 * 1vw));
  gap: 1vw;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

@media (max-width: 1100px) {
  .auction_product_container {
    width: 100vw;
    grid-template-columns: repeat(2, calc(78.5 / 2 * 1vw));
  }
}

@media (max-width: 750px) {
  .auction_product_container {
    box-sizing: border-box;
    width: 98vw;
    margin: auto;
    grid-template-columns: repeat(1, 80vw);
    gap: 3vh;
    justify-content: center;
    align-items: center;
  }
  .form {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.load_more_button {
  cursor: pointer;
  width: auto;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  background-color: white;
  border-style: none;
  font-family: "Gilroy-Light";
  box-sizing: border-box;
  transition: 400ms ease-out;
  &:hover {
    font-family: "Gilroy-SemiBold";
  }

  @media (max-width: 750px) {
    margin-top: 3vw;
    font-size: .9rem;
  }
}

.spin_container {
  height: 20vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products__header {
  width: 100vw;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
}
