.footer {
    max-width: 100vw;
    background: #eeeeee; // background color similar to the image
    padding: 40px 10vw; // added padding for proper spacing
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Gilroy-Regular", sans-serif;
    color: #000;
    line-height: 1.6;
    margin-top: 5vh;

    &__quarter {
        width: 18%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &__text {
            margin-bottom: 1vh;
            letter-spacing: 0.2px;
            font-size: 14px;
            font-family: "Gilroy-Regular"; // Regular font for normal text
        }

        &__text__light {
            font-family: "Gilroy-Light"; // Light font for thinner text
        }

        &__text__bold {
            font-family: "Gilroy-SemiBold"; // SemiBold font for bold text
        }
    }

    &__info {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1vh;

        &__contact {
            margin-top: 1.5vh;

            &__address {
                margin-bottom: 0.5vh;
                font-size: 14px;
                font-family: "Gilroy-Regular"; // Regular font for normal text
            }

            &__phone,
            &__email {
                font-size: 14px;
                font-family: "Gilroy-Regular"; // Regular font for normal text
            }
        }

        &__socials {
            display: flex;
            gap: 10px;

            .social-icon {
                height: 25px !important;
                width: 25px !important;
            }
        }
    }

    // Responsive styling
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        &__quarter,
        &__info {
            width: 100%;
            margin-bottom: 20px;
        }

        &__socials {
            justify-content: flex-start;
            margin-top: 20px;
        }
    }

    @media (max-width: 480px) {
        padding: 20px 5vw;

        &__quarter {
            &__text {
                font-size: 12px;
            }
        }

        &__info {
            &__contact {
                &__address,
                &__phone,
                &__email {
                    font-size: 12px;
                }
            }
        }

        &__socials {
            .social-icon {
                height: 20px !important;
                width: 20px !important;
            }
        }
    }
}
