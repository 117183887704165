.notification-notifications {
    text-decoration: none;
    width: 30px;
    height: 25px;
}

.notification-notifications .notification-icon {
    width: 25px;
    position: relative;
    cursor: pointer;
}

.notification-notifications .notification-count {
        height: 17px;
        width: 17px;
        max-width: 17px;
        min-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -4px;
        right: -2px;
        font-size: 10px;
        border-radius: 50%;
        background-color: #ff0000;
        font-weight: 400;
        color: #fff;
}
.notification-notifications .notification-container {
    width: 320px;
    position: absolute;
    background: #fff;
    border-radius: 6px;
    z-index: 2;
    box-shadow: -1px 2px 7px -3px rgba(0,0,0,.3);
    display: flex;
    flex-direction: column;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    height: 390px;
    overflow-y: hidden;
    transform: translateX(-60%) !important;

    @media (max-width: 450px) {
        width: 300px;
    }
}

.notification-notifications .notification-container .notification-items {
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
}

.notification-notifications .notification-container .notification-item {
    padding-left: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #ffffff;
    border-bottom: 1px solid #e9e9e9;
    white-space: normal;
    word-break: break-word;
    font-size: .8rem;
    margin: 2px 1px;
    border-radius: 3px ;
}

.notification-notifications .notification-container .notification-item button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.notification-notifications .notification-container .notification-item button:hover svg {
    color: #ff0000;
}

.notification-notifications .notification-container .notification-header {
    display: flex;
    justify-content: center;
    padding: 8px;
    background-color: #e9e9e9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-weight: 500;
    font-size: 14px;
}

.notification-empty-notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: #999;

}

.notification-empty-notifications div {
    visibility: hidden;
}

.notification-empty-notifications div::before {
    content: 'bildirim yok';
    visibility: visible;
    display: flex;
    justify-content: center;
}


