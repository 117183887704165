@import "../styling/Animations.scss";

.auctions {
  box-sizing: border-box;
  // background-color: rgb(174, 12, 12);
  color: white;
  height: auto;
  width: 100vw;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

@keyframes leftt {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
    transform: scale(2);
  }
}

.current_auctions_container {
  width: 80vw;
  // border: 1px solid black;
  margin: auto;
  display: flex;
  flex-direction: column;
  // align-items: center;
  position: relative;
  margin-top: 5vh;
}

.past_auctions_container {
  width: 80vw;
  // border: 1px solid black;
  margin: auto;
  display: flex;
  flex-direction: column;
  // align-items: center;
  position: relative;
  margin-top: 5vh;
}

.spin {
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

// @media (max-width: 870px) {
//   .current_auctions_container {
//     text-align: center;
//   }
//   .past_auctions_container {
//     text-align: center;
//   }
// }
