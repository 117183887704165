.accountPage {
  display: flex;
  width: 80vw;
  margin: 0 auto;
  position: relative;

  .sidebar {
    width: 20vw;
    min-height: 60vh;
    transition: all 0.3s ease-in-out;
    position: sticky;

    ul {
      list-style: none;
      padding: 0;
    }

    ul li {
      margin-bottom: 15px;
      font-size: 16px;
      cursor: pointer;
    }

    li:first-child {
      margin-top: 5vh;
    }

    ul li a {
      text-decoration: none;
      color: #333;
    }

    .active {
      color: #bc2832;
      font-weight: 800;
    }

    .upPoint {
      margin-top: 40px;
    }

    .logout {
      margin-top: 50px;
      font-weight: 600;
      color: #333;
      cursor: pointer;
    }
  }

  .personalInfo {
    width: 60vw;
    padding: 20px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    background: linear-gradient(to right, rgba(239, 239, 239, 0.3), white, white, white);

    h2 {
      font-weight: 800;
      margin-bottom: 20px;
      color: #333;
      font-size: 24px;
    }

    .editButtons {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      button {
        padding: 10px 15px;
        background-color: #ff4500;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          background-color: #e03e00;
        }
      }
    }
  }

  .sidebarToggle {
    display: none;
  }

  .overlay {
    display: none;
  }

  @media (max-width: 768px) {
    width: 100vw;
    flex-direction: column;

    .sidebar {
      width: 200px;
      min-height: 50vh;
      max-width: 80vw;
      position: fixed;
      top: 100px;
      left: -250px;
      background: white;
      z-index: 1000;
      transition: left 0.3s ease-in-out;
      overflow-y: auto;

      &.open {
        left: 0px;
      }

      // Menü öğelerinin orijinal stillerini koruyoruz
      ul {
        padding: 20px;
      }

      ul li {
        margin-bottom: 15px;
        font-size: 16px;
      }

      li:first-child {
        margin-top: 0; // Mobile'da üst kenardan boşluk kaldırıldı
      }

      .logout {
        margin-top: 50px;
      }
    }

    .sidebarToggle {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 19%;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      left: 10px;
      z-index: 1001;
      background: white;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      padding: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s ease-in-out;
      //padding: 0; // Padding'i kaldırıyoruz

      svg {
        width: 20px; // SVG boyutunu ayarlayabilirsiniz
        height: 20px;
      }

      &.open {
        opacity: 0;
        pointer-events: none;
      }
    }

    .overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;

      &.open {
        display: block;
      }
    }

    .personalInfo {
      width: 90vw;
      margin: 0 auto;
      padding: 20px 0;
    }
  }
}