@import "../styling/Animations.scss";

.signup {
  box-sizing: border-box;
  // background: gray;
  display: flex;
  justify-content: center;
  padding: 10vh;
  width: 100vw;
  height: auto;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.signup__form__title {
  font-family: "Gilroy-Black";
  font-size: 20px;
  color: black;
}

.signup__form {
  height: 70vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.558);
}

.signup__form__input {
  box-sizing: border-box;
  border: none !important;
  box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.551);
  border-radius: 5px;
  width: 20vw;
  padding: 0.5vw;
  font-size: 17px;
  height: auto;
}

.signup__form__btn {
  background-color: black;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background-color: #ececec;
    color: black;
  }
}

.signup__form__login {
  font-family: "Gilroy-Black";
}


@media (max-width: 768px) {
  .signup {
    box-sizing: border-box;
    // background: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh;
    width: 100vw;
    // position: absolute;
  }

  .signup__form {
    min-height: 75vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.558);
  }
  .signup__form__input {
    box-sizing: border-box;
    width: 70vw;
    padding: 1vw;
    height: 5vh;
    margin-bottom: 2vh;
    padding-left: 15px;
  }

  .signup__form__title {
    font-family: "Gilroy-SemiBold";
    font-size: 21px;
    margin-bottom: 20px;
  }
}

.signup__form__password_container {
  position: relative;
  display: flex;
  align-items: center;
}

.signup__form__password_toggle {
  position: absolute;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  @media (max-width: 750px) {
    margin-bottom: 2vh;
  }
}
