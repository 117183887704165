.popup-content {
  font-size: 1rem;
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px black;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (max-width: 900px) {
    font-size: 0.8rem;
  }
}

.popup-content h3 {
  font-family: 'Gilroy-Light';
}

.btnContainer {
  width: 80%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 1vh;
  justify-content: center;
}

.btn {
  width: 80%;
  border-radius: 10px;
  background-color: black;
  border: 1.5px solid white;
  color: white;
  font-family: 'Gilroy-SemiBold';
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }

}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-content {

  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-content{margin:auto;width:50%}
[role=tooltip].popup-content{width:200px;box-shadow:0 0 3px rgba(0,0,0,.16);border-radius:5px}
.popup-overlay{
  background:rgba(0,0,0,.5)
}
