.signup__form__input {
  box-sizing: border-box;
  border: none !important;
  box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.551);
  border-radius: 5px;
  width: 20vw;
  padding: 0.5vw;
  font-size: 17px;
  height: auto;
}

@media (max-width: 750px) {
  .signup__form__input {
    box-sizing: border-box;
    border: none !important;
    box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.551);
    border-radius: 5px;
    width: 70vw;
    height: 5vh;
    padding: 0.5vw;
    font-size: 17px;
    margin-bottom: 2vh;
    padding-left: 15px;
  }
}
