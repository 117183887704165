@import "./Animations.scss";

@mixin auction_layout() {
    width: 80vw;
    background-color: #f6f6f6;
    color: black;
    height: 300px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 1vh;
    padding-left: 2vw;
    padding-right: 2vw;
    @include fadeIn;
    //box-shadow: 5px 5px 2.5px #dfdfdf7e;
    cursor: pointer;
}