@import "../../styling/AuctionStyles.scss";

.current_auction {
  @include auction_layout;
  background-image: url("../../assets/current_back.png");
  background-size: cover;
}

.red {
  background-color: #950101;
}

.image_container {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 15px;
  // background-color: red;
}

.img {
  height: 100%;
  width: auto;
  max-width: 100%;
  border-radius: 10px;
  transition: 800ms;
  cursor: pointer;
  object-fit: contain;
}

.auction_info_container {
  width: 32.5%;
  height: 100%;
  // background-color: yellow;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5vh;
  // align-items: center;
}

.auction_date_container {
  width: 30%;
  height: 100%;
  // background-color: yellow;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}


.auction_name {
  font-size: 30px;
  font-family: "Gilroy-SemiBold";
  width: 100%;
  margin: 30px 0;
}

.info_text {
  margin-bottom: 1vh;
  font-size: 15px;
  // background-color: yellow;
  min-width: 7vw;
  margin-right: 2vw;
}

.btn_container {
  width: 20%;
  // background-color: brown;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.current_auction__btn {
  box-sizing: border-box;
  width: 100%;
  //height: 7vh;
  border-radius: 20px;
  padding: 20px 0;
  background-color: black;
  border: 1px solid white;
  color: white;
  // border: none;
  font-family: "Gilroy-SemiBold";
  cursor: pointer;
  transition: 300ms;
  // &:hover {
  //   background-color: #950101;
  //   color: white;
  // }

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }

  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  /*
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
    background-color: white;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(black, 15%);
    color: white;
    transition: all 0.3s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
   */
}

@media (max-width: 870px) {
  .current_auction {
    flex-direction: column;
    height: 800px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .image_container {
    width: 100%;
    height: 50%;
  }
  .img {
    margin: auto;
  }

  .auction_info_container {
    width: 100%;
    height: 25%;
    text-align: center;
  }

  .auction_date_container {
    width: 100%;
    padding: 5%;
    text-align: center;
    height: 15%;
  }
  .btn_container {
    width: 80%;
    height: 10%;
    margin-bottom: 20px;
  }

  .current_auction__btn {
    letter-spacing: 1.5px;
    font-size: 17px;
    height: 100%;
    padding: 12px 0;
  }
}
