@import "../../styling/Fonts.scss";

.nav {
  box-sizing: border-box;
  width: 100vw;
  height: 14vh;
  //   border: 4px solid $MUZAYEDE_BLACK;
  display: flex;
  background-color: white;
  flex-direction: row;
  align-items: end;
  padding-bottom: 1vh;
  padding-left: 10vw;
  padding-right: 10vw;
  position: sticky;
  top: 0;
  z-index: 999;
}

.mobile_dropdown {
  display: none;
  position: sticky;
}

.logoContainer {
  // 35
  width: 20vw;
  cursor: pointer;
  // border: 1px solid black;
  display: flex;
  align-items: end;
}

.logoContainer__logo {
  font-family: "Gilroy-Black";
  height: 5vh;
  width: auto;
}

.page_buttons_container {
  //35
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  // border: 1px solid black;
}

.page_btn {
  margin-right: 2vw;
  transition: 500ms;
  font-size: 19px;
  color: gray;
  cursor: pointer;
  &:hover {
    transition: 500ms;
    box-shadow: 0 1px gray;
  }
  font-family: "Gilroy-SemiBold";
  letter-spacing: 0.5px;
}

.page_btn_light {
  font-family: "Gilroy-Light";
  margin-right: 2vw;
  transition: 500ms;
  font-size: 19px;
  color: gray;
  cursor: pointer;
  &:hover {
    transition: 500ms;
    box-shadow: 0 1px gray;
  }
  letter-spacing: 0.5px;
}

.ls_btn_container {
  box-sizing: border-box;
  //20
  width: 10vw;
  // border: 1px solid black;
  display: flex;
  justify-content: space-around;
  // align-items: end;
}

.ls_btn_container__icon {
  color: #7d7a7a;
  transition: 300ms;
  letter-spacing: 0.5px;
  cursor: pointer;
  &:hover {
    color: black;
    font-weight: 500;
  }
  font-size: 18px;
  &:hover {
    transition: 500ms;
    box-shadow: 0 1px gray;
  }
}

.ls_btn_container__icon_splitter {
  background-color: #7d7a7a;
  height: 3vh;
  width: 0.05vw;
}

$menu1AnimSpeed: 300ms;
$menu2AnimSpeed: 175ms;
$menu3AnimSpeed: 200ms;
$menu4AnimSpeed: 300ms;
$menu5AnimSpeed: 300ms;

.dropdown_item_container {
  width: 150px;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav__menu {
  transition: 500ms;
  z-index: 40;
  text-align: center;
  width: 150px;
  height: 4vh;
  cursor: pointer;
  &:hover {
    background-color: darken(black, 2%);
  }
  &:hover > .dropdown_item_container li {
    // height: 4vh;
    display: flex;
    z-index: 50;
    width: 150px;
    margin: 0;
    padding: 0;
  }
  // Menu 1
  @for $i from 1 through 5 {
    &:hover > .dropdown_item_container li:nth-child(#{$i}) {
      z-index: 50;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 6vh;
      width: 150px;
      border: 0.5px solid rgba(0, 0, 0, 0.091);
      background-color: white;
      color: black;
      opacity: 0;
      margin: 0px;
      animation: menu1 ease-in-out forwards;
      animation-duration: $menu1AnimSpeed;
      animation-delay: (calc($menu5AnimSpeed / 2)) * ($i - 1);
      @keyframes menu1 {
        from {
          opacity: 0;
          transform: rotateX(-180deg);
        }
        to {
          opacity: 1;
          transform: rotateX(0deg);
        }
      }
      transition: 500ms;
      font-family: "Gilroy-SemiBold";
      color: gray;
      &:hover {
        background-color: #ececec;
        color: black;
      }
    }
    &:hover > .dropdown_item_container li:first-child {
      margin-top: 10px;
    }
  }
}

li:first-child {
  margin-top: 100px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nav__btn {
  height: 4vh;
  width: 150px;
  display: flex;
  margin: 0px;
  align-items: end;
  justify-content: center;
  background-color: white;
  color: black;
}

.btn_name {
  font-family: "Gilroy-SemiBold";
  font-size: 18px;
}

.profile_icon {
  margin-bottom: 2px;
}

.nav__menu-items {
  display: none;
  width: 150px;
  // height: 6vh;
  background-color: black;
  &:hover {
    background-color: red;
  }
  margin: 0px;
  font-size: 15px;
  letter-spacing: 0.5px;
}

@media (max-width: 1160px) {
  .page_buttons_container {
    display: none;
    width: auto;
  }
  .nav__menu {
    transition: 500ms;
    z-index: 40;
    text-align: center;
    width: 80px;
    height: 4vh;
    cursor: pointer;
    &:hover {
      background-color: darken(black, 2%);
    }
    &:hover > .dropdown_item_container li {
      // height: 4vh;
      display: flex;
      z-index: 50;
      width: 80px;
      margin: 0;
      padding: 0;
    }
    // Menu 1
    @for $i from 1 through 5 {
      &:hover > .dropdown_item_container li:nth-child(#{$i}) {
        z-index: 50;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        height: 6vh;
        width: 80px;
        border: 0.5px solid rgba(0, 0, 0, 0.091);
        background-color: white;
        color: black;
        opacity: 0;
        margin: 0px;
        animation: menu1 ease-in-out forwards;
        animation-duration: $menu1AnimSpeed;
        animation-delay: (calc($menu5AnimSpeed / 2)) * ($i - 1);
        @keyframes menu1 {
          from {
            opacity: 0;
            transform: rotateX(-180deg);
          }
          to {
            opacity: 1;
            transform: rotateX(0deg);
          }
        }
        transition: 500ms;
        font-family: "Gilroy-SemiBold";
        color: gray;
        &:hover {
          background-color: #ececec;
          color: black;
        }
      }
      &:hover > .dropdown_item_container li:first-child {
        margin-top: 10px;
      }
    }
  }
  .nav__btn {
    width: 80px;
    align-items: end;
  }
  .ls_btn_container__icon {
    font-size: $normal;
    width: auto;
    margin-right: 10px;
  }

  .ls_btn_container__icon_splitter {
    height: 1.5vh;
  }

  .btn_name {
    font-size: $small;
  }

  .logoContainer {
    width: auto;
  }

  .logoContainer__logo {
    height: 3.5vh;
  }
  .nav {
    justify-content: space-between;
  }
  .btn_container {
    width: auto;
  }
  .dropdown_item_container {
    width: 80px;
  }
}

@media (max-width: 870px) {
  .ls_btn_container__icon_splitter {
    display: none;
  }
  .page_btn {
    font-size: $small;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
    height: 100px;
    padding-bottom: 0;
  }
  .nav__menu {
    transform: translateY(3.5px);
  }
  .ls_btn_container {
    box-sizing: border-box;
    //20
    width: 25vw;
    // border: 1px solid black;
    display: flex;
    // justify-content: space-around;
    justify-content: end;
    align-items: center;
    height: 100%;
  }
  .ls_btn_container__icon {
    box-sizing: border-box;
    font-size: 18px;
    margin-top: 5px;
  }

  // .ls_btn_container__icon_splitter {
  //   height: 1.5vh;
  // }

  .btn_name {
    font-size: .8rem;
  }
  .logoContainer {
    position: absolute;
    transform: translateY(5px);
    left: calc((90 - 10) / 2 * 1vw);
    height: 100%;
    align-items: center;
  }
  .signup_btn {
    display: none;
  }
  .mobile_drowdown {
    display: flex;
    position: fixed;
    background-color: red;
    height: 7vh;
    width: 100vw;
    top: 14vh;
    z-index: 999999999;
  }

  .nav__btn {
    align-items: center;
  }
}

.notification {
  display: flex;
}


.user_actions {
    display: flex;
    align-items: flex-end;
}

