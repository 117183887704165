.myWinnings {
  padding: 0 20px 20px 20px;
  width: 100%;

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .artworkCard {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }

    .artworkImage {
      width: 100%;
      aspect-ratio: 4/3;
      display: block;
      object-fit: contain;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
    }

    .artworkInfo {
      padding: 10px;
      text-align: start;
      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
      }

      p {
        margin: 4px 0;
        color: #666;
        font-size: 14px;
      }

      span {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .artworkDetail {
    display: flex;
    flex-direction: row; /* Detay görünümde görsel ve bilgi yanyana */
    justify-content: space-around;
    gap: 20px;
    margin: 20px 0;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    .artworkImageContainer {
      position: relative;
      width: 70%;
      max-height: 500px;
      overflow: hidden;
      object-fit: contain;

      @media (max-width: 450px) {
        width: 100%;
      }

      .leftArrow,
      .rightArrow {
        position: absolute;
        top: 0;
        width: 50px;
        cursor: pointer;
        height: 100%;
      }

      .leftArrow {
        left: 0;
        background: rgba(0, 0, 0, 0.2);
      }

      .rightArrow {
        right: 0;
        background: rgba(0, 0, 0, 0.2);
      }

      .artworkImage {
        width: 100%;
        max-height: inherit;
        display: block;
        object-fit: contain;
      }
    }

    .artworkInfoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      width: 25%;
      margin-left: 5%;

      @media (max-width: 450px) {
        flex-direction: column;
        margin: 0;
        width: 100%;
      }



      .artworkDate {
        margin-bottom: 20px;
        font-size: 1em;
        color: #888;

        p {
          letter-spacing: 0.5px;
          color: #f32f1e;
          margin: 16px 0 0 0;

          @media (max-width: 450px) {
            margin: 0;
          }

        }


      }

      .ownerInfo {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        @media (max-width: 450px) {
          flex-direction: row;

          .ownerDetail {
            flex-direction: column;
            margin-left: 10px;
            display: flex;
            justify-content: space-around;
          }
        }

        .ownerImage {
          width: 100px;
          margin-bottom: 10px;

          @media (max-width: 450px) {
            margin: 0;
          }
        }

        h3 {
          margin: 0;
          font-size: 1em;
          font-weight: bold;
        }
      }

      .underArtistName {
        margin-top: 10px;
        p {
          font-size: 0.9em;
          margin: 0;
          font-family: Gilroy-Thin;
        }
      }


    }
  }
}
.backButton {
  align-self: flex-start;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.artworkDescription {
  max-width: 70%;
  text-align: left;
  font-size: 1em;
  margin: 0;

  @media (max-width: 450px) {
    max-width: 100%;
  }

  h5 {
    margin: 0;
    font-size: 1.2em;
  }
}
