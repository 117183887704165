.top_section__image_container {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 55vh;
  box-sizing: border-box;
  padding: 20px;
}

.image_container__big_image {
  height: 75%;
  min-width: 90%;
  width: 90%;
  max-width: 90%;
  align-self: center;
  cursor: pointer;
  object-fit: contain;
}

.fadeInClass {
  animation: fadeIn 500ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.big_image_container {
  height: 80%;
  width: 100%;
  background-color: #ececec4e;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 800ms;
}

.image_container__sub_image_container::-webkit-scrollbar {
  height: 5px;
  background-color: rgb(231, 231, 231);
  // color: black;
}

.image_container__sub_image_container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.242);
  height: 5px;
  color: black;
}

.image_container__sub_image_container {
  width: 100%;
  height: 20%;
  // flex-direction: row;
  // justify-content: start;
  margin-top: 0;
  overflow: auto;
  box-sizing: border-box;
  white-space: nowrap;
  overflow-y: hidden;
}

.sub_image {
  box-sizing: border-box;
  height: 100%;
  width: 25%;
  margin-right: 1px;
  cursor: pointer;
  display: inline-block;
  background-color: rgba(236, 236, 236, 0.3058823529);
  object-fit: contain;
}

@media (max-width: 870px) {
  .top_section__image_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    height: auto;
    box-sizing: border-box;
    padding: 0px;
  }
  .big_image_container {
    height: auto;
    width: 100%;
    background-color: #ececec4e;
    padding: 0px;
  }
  .image_container__big_image {
    height: 30vh;
    min-width: 100%;
    width: 100%;
    align-self: center;
    cursor: pointer;
    object-fit: contain;
  }
  .image_container__sub_image_container {
    width: 100%;
    min-width: 100%;
    height: 8vh;
    // flex-direction: row;
    // justify-content: start;
    margin-top: 3px;
    overflow: auto;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-y: hidden;
    object-fit: contain;
  }
  .sub_image {
    box-sizing: border-box;
    height: 100%;
    width: 28%;
    margin-right: 1px;
    cursor: pointer;
    display: inline-block;
  }
  .image_container__sub_image_container::-webkit-scrollbar {
    height: 5px;
    background-color: rgb(231, 231, 231);
    // color: black;
  }

  .image_container__sub_image_container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.242);
    height: 5px;
    color: black;
  }
}
