.hamburgerMenu {
  position: relative;

  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
  }

  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
  }

  .line2 {
    stroke-dasharray: 60 60;
  }

  .line3 {
    stroke-dasharray: 60 207;
  }

  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }

  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
  }

  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }

  .menuItems {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;

    &.menuOpen {
      transform: translateX(-5%); /* Açıldığında menü soldan görünür hale gelir */
    }

    &.menuClose {
      transform: translateX(-300%); /* Kapandığında menü soldan kaybolur */

    }

    .menuItem {
      font-size: 1.5em;
      cursor: pointer;
      color: gray;
      padding: 20px 80px 20px 20px;
      font-family: Gilroy-SemiBold;

      &:hover {
        font-weight: bold;
      }
    }
  }
}

.display{
  display: block;
}

@media (min-width: 1160px) {
  .menu {
    display: none;
  }
  .hamburgerMenu {
    display: none;
  }
}


