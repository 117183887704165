.homepage {
  font-family: 'Gilroy-Regular';
  //font-style: italic;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;

  .header {
    text-align: center;
    margin-bottom: 40px;

    .headerTagline {
      font-family: 'Gilroy-Light';
      font-style: italic;
      font-size: 1rem;
      color: #bc2832;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    .headerTitle {
      font-family: 'Gilroy-Light';
      font-size: 2.5rem;
      line-height: 2rem;
      color: black;
      margin-bottom: 20px;
      font-style: italic;

      em {
        font-family: 'Gilroy-Thin';
        font-size: 1.7rem;
      }

      strong {
        font-family: 'Gilroy-Black';
      }
    }
  }

  .auctionSection {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 5vw 50px 5vw;
    @media (max-width: 700px) {
      flex-direction: column;
      margin: 0 10px 20px 10px;
    }

    .imageSection {
      width: 55%;
      margin-right: 3%;

      @media (max-width: 700px) {
        width: 100%;
        margin-right: 0;
      }

    }

    .textSection {
      width: 42%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .container {
        max-height: 33%;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    .liveLabel {
      text-align: center;
      font-family: 'Gilroy-Semibold';
      font-style: normal;
      color: #fff;
      background-color: red;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 50px;
      width: 10%;

      @media (max-width: 768px) {
        font-size: 0.7rem;
        padding: 3px 5px;
        width: auto;

      }
    }

    .title {
      font-family: 'Gilroy-Black';
      font-size: 2rem;
      margin: 0;

      @media (max-width: 1024px) {
        font-size: 1.5rem;
      }

      @media (max-width: 768px) {
        font-size: .8rem;
      }
    }

    .auctionName {
      top: 50%;
      font-family: 'Gilroy-Black';
      font-size: 2.5rem;
      margin: 0;

      @media (max-width: 1024px) {
        font-size: 1.8rem;
      }

      @media (max-width: 768px) {
        font-size: 1rem;
        margin-bottom: 10px;
      }
    }


    .artist {
      font-family: 'Gilroy-Light';
      font-size: 1.5rem;
      color: #555;
    }

    .date {
      font-family: 'Gilroy-Regular';
      font-size: 1.2rem;
      font-style: normal;
      color: #777;
      margin-bottom: 20px;
      margin: 0;

      @media (max-width: 700px) {
        font-size: 0.8rem;
        margin-bottom: 10px;
      }
    }

    .auctionImage {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      aspect-ratio: 2/1;
      object-fit: contain;
      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }
      cursor: pointer;
    }
  }

  .storeSection, .blogSection {
    margin: 0 2vw 50px 2vw;

    .sectionTitle {
      font-family: 'Gilroy-Black';
      font-style: normal;
      font-size: 1.8rem;
      text-align: center;
      margin-bottom: 40px;
    }

    .carousel {
      @media (min-width: 700px) {
        margin: 0 15px;
        :global .slick-list {
          margin: 0 15px;
        }
      }

      .slick-slide {
        width: auto;
        display: flex;
        justify-content: center;
        padding: 0 10px; // Add gap between items
      }

      .carouselItem {
        padding: 0 10px;
        text-align: left;
        aspect-ratio: 4 / 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-style: normal;

        .storeImage {
          width: 100%;
          height: 80%;
          cursor: pointer;
          object-fit: contain;
        }

        .artworkTitle {
          font-family: 'Gilroy-Black';
          font-size: 1.2rem;
          color: #333;
          margin: 0;
        }

        .artistName {
          font-family: 'Gilroy-Light';
          font-size: 1rem;
          color: #777;
          margin: 0;
        }
      }

      :global {
        .slick-prev,
        .slick-next {
          top: 40%;
          width: 40px;
          height: 40px;
          z-index: 1;

          &:before {
            color: black;
          }
        }


        .slick-dots {
          position: relative;
          bottom: -10px;
          display: flex !important;
          justify-content: center;

          li {
            margin: 0 5px;
            width: 10px;
            height: 10px;

            button {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #777;
              border: none;
              cursor: pointer;

              &:before {
                display: none;
              }
            }

            &.slick-active button {
              background: #333;
            }
          }
        }
      }
    }
  }
}

.spin_container {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

