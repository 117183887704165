@keyframes fadeOut {
    from {
    opacity: 1;
    }
    to {
    opacity: 1;
    }
  }
  
@keyframes fadeIn {
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
}

@mixin fadeOut() {
    animation: fadeOut 500ms forwards ease-out;
}

@mixin fadeIn() {
    animation: fadeIn 500ms forwards;
}