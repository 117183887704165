body {
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#root {
  flex: 1;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Gilroy-Regular';
}

@font-face {
  font-family: 'Gilroy-Black';
  src: local('Gilroy-Black'), url(./fonts/Gilroy-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'),
    url(./fonts/Gilroy-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Thin';
  src: local('Gilroy-Thin'), url(./fonts/Gilroy-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'),
    url(./fonts/Gilroy-SemiBold.ttf) format('truetype');
}
