@import "../styling/Animations.scss";

.login {
  box-sizing: border-box;
  // background: gray;
  display: flex;
  justify-content: center;
  padding: 10vh;
  width: 100vw;
  // position: absolute;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.login__form__title {
  font-family: "Gilroy-Black";
  font-size: 20px;
  color: black;
  margin-bottom: 3vh;
}

.login__form {
  height: 60vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.558);
}

.login__form__input {
  box-sizing: border-box;
  border: none !important;
  box-shadow: 0px 0px 1px 1px rgba(128, 128, 128, 0.551);
  border-radius: 5px;
  width: 20vw;
  padding: 0.5vw;
  font-size: 17px;
  height: auto;
  margin-bottom: 2vh;
}

.login__form__btn {
  background-color: black;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background-color: #ececec;
    color: black;
  }
  padding: 1vh !important;
}

.login__form__login {
  font-family: "Gilroy-Black";
}

@media (max-width: 1024px) {
  .login {
    box-sizing: border-box;
    // background: gray;
    display: flex;
    justify-content: center;
    padding: 2vh;
    width: 100vw;
    // position: absolute;
  }

  .login__form {
    height: 60vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.558);
  }
  .login__form__input {
    box-sizing: border-box;
    width: 40vw;
    padding: 1vw;
    height: 5vh;
    margin-bottom: 2vh;
    padding-left: 15px;
  }

  .login__form__title {
    font-family: "Gilroy-SemiBold";
    font-size: 21px;
  }

  .login__form__btn {
    padding: 0 !important;
  }
}

@media (max-width: 500px) {
  .login {
    box-sizing: border-box;
    // background: gray;
    display: flex;
    justify-content: center;
    padding: 2vh;
    width: 100vw;
    // position: absolute;
  }

  .login__form {
    height: 60vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.558);
  }
  .login__form__input {
    box-sizing: border-box;
    width: 70vw;
    padding: 1vw;
    height: 5vh;
    margin-bottom: 2vh;
    padding-left: 15px;
    // close last child for padding left
  }

  .login__form__title {
    font-family: "Gilroy-SemiBold";
    font-size: 21px;
  }

  .login__form__btn {
    font-size: 15px;
  }

  .span {
    font-size: 15px;
  }
}

