.blogDetailContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  //background-color: #f9f9f9;
  border-radius: 10px;
  //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.blogImage {
  width: 100%;
  display: flex;
  justify-content: center;

}
.blogImage img {
  max-width: 90% !important;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.blogContent {
  text-align: left;
  max-width: 100%;
  overflow: hidden;
}

.blogContent img {
  max-width: 100%;
  height: auto;
  display: block;
}

.blogTitle {
  font-size: 2.2em;
  margin-bottom: 30px;
  color: black;
  text-align: center;
}

.blogMeta {
  font-size: 0.9em;
  color: black;
  display: flex;
  justify-content: end;
  letter-spacing: .5px;
}

.redLine {
  height: 2px;
  background-color: red;
  margin: 10px 0;
}

.blogText {
  font-size: 1.1em;
  line-height: 1.6;
  color: black;
  * {
    font-family: Gilroy-Regular !important;
  }

  }

.spin_container {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
