.artist_card {
  box-sizing: border-box;
  width: auto;
  aspect-ratio: 3/4;
  background-color: rgba(128, 128, 128, 0.337);
  // margin: 0.2vw;
  display: flex;
  align-items: end;
  font-size: 22px;
  letter-spacing: 1px;
  color: gray;
  font-family: "Gilroy-Black";
  transition: 300ms;
  filter: grayscale(100%);
  cursor: pointer;
  &:hover {
    filter: grayscale(0%);
    .artist_card_name {
      font-size: 30px;
      background-color: rgba(126,5,5, 0.8);
      width: 100%;
      color: white;
      bottom: 0;
      padding-bottom: 1.5%;
      padding-top: 1.5%;
    }
    .artist_card__img {
      opacity: 1;
    }
  }
  position: relative;
}

.artist_card__img {
  width: 100%;
  height: 100%;
  opacity: 0.35;
  transition: 500ms;
  object-fit: cover;

}

.artist_card_name {
  font-size: 30px;
  opacity: 1;
  color: #6d6d6d;
  position: absolute;
  font-family: "Gilroy-Black";
  bottom: 6%;
  text-align: start;
  box-sizing: border-box;
  padding-left: 10%;
  transition: 500ms;
  text-transform: lowercase;
  letter-spacing: 1.2px;
  line-height: 30px;
}
