@import "../styling/Animations.scss";

.product_details {
  box-sizing: border-box;
  // background: gray;
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-left: 10vw;
  align-items: center;
  color: black;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.top_section {
  display: flex;
  flex-direction: row;
  width: 80vw;
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.artist_info {
  margin-top: 4vh;
  min-height: 20vh;
  width: 80vw;
  border-radius: 10px;
  margin-bottom: 2vh;
  box-sizing: border-box;
  background-color: #ececec4e;
  display: flex;
  flex-direction: row;
}

.artist_photo {
  box-sizing: border-box;
  height: 12vh;
  width: 12vh;
  border-radius: 50%;
  margin-right: 1vw;
  margin: auto;
  object-fit: cover;
}

.artist_name {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.artist_info_left {
  cursor: pointer;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  max-width: 20%;
  //min-width: 10%;
  background-color: #ececec5a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.artist_info_right {
  box-sizing: border-box;
  width: 100% !important;
  min-width: 80%;
  // background-color: blue;
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
}

.bid_list {
  width: 80vw;
  border-radius: 10px;
  background-color: #ecececa8;
  margin-bottom: 2vh;
  box-sizing: border-box;
  padding: 15px;
}

.bid_table {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
}

.bid_header {
  box-sizing: border-box;
  height: 4vh;
  padding-left: 15px;
  width: 100%;
  // font-family: "Gilroy-SemiBold";
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.bid_item {
  box-sizing: border-box;
  height: 5vh;
  padding-left: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 1.5vh;
}

.bid_item_text {
  width: 33.33%;
}

.bids_title {
  font-size: 22px;
  margin-left: 10px;
}

@media (max-width: 700px) {
  .product_details {
    box-sizing: border-box;
    // background: gray;
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-left: 0px;
    color: black;
  }
  .top_section {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-top: 5vh;
    margin-bottom: 0vh;
  }
  .artist_info {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

  }

  .artist_info_left {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    max-width: 100%;
    background-color: #ececec4e;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .artist_photo {
    box-sizing: border-box;
    height: 10vh;
    width: 10vh;
    border-radius: 50%;
    margin: auto;
    object-fit: cover;
  }
  .bid_item {
    box-sizing: border-box;
    height: 5vh;
    font-size: 13px;
    text-align: center;
  }
  .bid_header {
    font-size: 13.5px;
    padding-left: 5px;
    text-align: center;
  }
  .bids_title {
    font-size: 18px;
  }
  .bid_list {
    text-align: center;
  }
}

.spin_container {
  height: 20vh;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

