@import "../../styling/Animations.scss";

.top_section__info {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 55vh;
  // background-color: gold;
  box-sizing: border-box;
  padding: 15px;
}

.header_text {
  font-size: 16px;
  margin-bottom: 0.5vh;
}

.desc {
  font-size: 16px;
  margin-bottom: 0.5vh;
  font-style: normal;
}

.price_container {
  box-sizing: border-box;
  background-color: #ecececba;
  padding: 12px;
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  border-radius: 15px;
  margin-top: 1vh;
  margin-bottom: 1vh;
  position: relative;
}

.price {
  font-size: 20px;
}

.bid_container {
  height: 20vh;
  width: 100%;
  // background-color: purple;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.bid_text {
  font-size: 13px;
  margin-bottom: 5px;
}

// input[type='number']::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  //-webkit-appearance: none;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1em;
  cursor: pointer;
  opacity: 1;
}

input[type="number"]::-webkit-outer-spin-button {
  content: "\25B6";
  font-size: 18px;
  color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bid_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  padding: 15px;
  font-size: 17px;
  width: 30%;
  height: 5vh;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
}

.bid_input_container {
  position: relative;
  display: inline-block;
  width: 30%;
  // background-color: red;
  margin-bottom: 1vh;
}

.bid_input {
  box-sizing: border-box;
  width: 100%;
  height: 6vh;
  border-radius: 10px;
  outline: none;
  border: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  &:hover {
    cursor: pointer;
  }
  background-color: rgba(202, 198, 198, 0.342);
}

.bid_options {
  max-height: 20vh;
  overflow-y: scroll;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  transition: 400ms;
  flex-direction: column;
  &:hover {
    display: flex;
  }
  @include fadeIn;
  padding: 0px;
}

.bid_input_container:hover {
  .bid_options {
    display: block;
  }
}

.option {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  &:hover {
    background-color: #dadada;
  }
  cursor: pointer;
  text-align: center;
}

@media (max-width: 870px) {
  .top_section__info {
    width: 100%;
  }
  .bid_input_container {
    position: relative;
    display: inline-block;
    width: 100%;
    // background-color: red;
    margin-bottom: 1vh;
  }
  .bid_btn {
    width: 100%;
  }
}

.change {
  animation: moveDownSm 0.5s forwards ease-in;
}

@keyframes moveDownSm {
  from {
    opacity: 0;
    transform: translateY(-1.5vh);
  }
  to {
    opacity: 1;
    transform: translateY(0vh);
  }
}


.product_card__btn_container {
  width: 80%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1vh;
}

.product_card__btn_container__btn {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  border: 2px solid black;
  color: black;
  // border: none;
  font-family: 'Gilroy-SemiBold';
  cursor: pointer;
  transition: 300ms;
  // &:hover {
  //   background-color: #950101;
  //   color: white;
  // }

  display: inline-block;
  color: black;
  font-size: 0.9rem;
  letter-spacing: .02rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
    background-color: white;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(black, 15%);
    color: white;
    transition: all .3s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }

}

.change {
  animation: moveDownSm 0.5s forwards ease-in;
}

.banner {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  border: 2px solid black;
  color: black;
  // border: none;
  font-family: 'Gilroy-SemiBold';
  cursor: pointer;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 1rem;
  letter-spacing: .02rem;
  transition: all .3s;
}