.artwork_card {
    box-sizing: border-box;
    height: auto;
    width: auto;
    background-color: rgba(128, 128, 128, 0.337);
    // margin: 0.2vw;
    display: flex;
    align-items: end;
    font-size: 22px;
    letter-spacing: 1px;
    color: gray;
    font-family: "Gilroy-Black";
    transition: 300ms;
    cursor: pointer;
    &:hover {

      .artwork_card_name {
        font-size: 23px;
        background-color: rgba(172, 1, 1, 0.605);
        bottom: 0;
        width: 100%;
        color: white; 
      }
      .artwork_card__img {
        opacity: 1;
      }
    }
  }
  
  .artwork_card__img {
    width:100%;
    height: 100%;
    opacity: 0.70;
    transition: 500ms;
    object-fit: cover;
  }
  
  .artwork_card_name {
    font-size: 23px;
    opacity: 1;
    color: #6d6d6d;
    position: absolute;
    font-family: "Gilroy-Black";
    bottom: 7%;
    text-align: start; 
    box-sizing: border-box;
    padding-left: 10%;
    transition: 500ms;
    text-transform: lowercase;
    letter-spacing: 1.5px;
  }
  