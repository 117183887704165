.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  position: fixed;
  left: 14dvw;
  height: 76dvh;
  width: 70dvw;
  top: 20dvh;
  background-color: black;
  border-radius: 10px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.25);
  z-index: 99999999999999;
  animation: slide-down 400ms ease-out forwards;
}

.fadeInClass {
  animation: fadeIn 500ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modalHeader {
  width: 100%;
  background-color: black;
  height: 8dvh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalHeaderText {
  font-size: 20px;
  color: rgb(243, 243, 243);
}

.modalHeaderButton {
  position: absolute;
  right: 2%;
  cursor: pointer;
  transition: 500ms;
}

.modalHeaderButton:hover {
  scale: 1.03;
}

.modalContent {
  height: 100%;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  text-align: center;
}

.btnContainer {
  width: 30dvw;
  height: 10dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnBuy {
  box-sizing: border-box;
  font-size: 20px;
  width: 80%;
  height: 50px;
  border-radius: 3px;
  background-color: #1f9f5b;
  color: white;
  border: none;
  margin-bottom: 1dvh;
  cursor: pointer;
  &:hover {
    background-color: darken(#1f9f5b, 5%);
  }
  transition: 0.4s;
}

.icon {
  margin-right: 1dvw;
  cursor: pointer;
  transition: 500ms;
}

.icon:hover {
  transform: scale(1.1);
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.img {
  height: 82%;
  width: auto;
}

@media (max-width: 870px) {
  .modal {
    position: fixed;
    height: 65dvh;
    width: 100vw;
    left: 0px;
    top: 14dvh;
  }

  .modalContent {
    height: 57dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
