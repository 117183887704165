@import "../styling/Animations.scss";

.auction_details {
  box-sizing: border-box;
  // background-color: rgb(174, 12, 12);
  color: black;
  height: auto;
  max-width: 100vw;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.auction_details__header {
  margin-top: 5vh;
  width: 100vw;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.auction_details__header__text {
  font-size: 18px;
  margin-bottom: 0.4vh;
}

.bold {
  letter-spacing: 1px;
  font-family: "Gilroy-SemiBold";
}

.form {
  margin-top: 0.4vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.4vh;
}

::placeholder {
  color: black;
}

.form__text_inp {
  width: 10vw;
  box-sizing: border-box;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #ededed9d;
  letter-spacing: 0.5px;
  margin-left: 1vw;
  padding: 10px;
  padding-left: 10px;
  transition: 300ms;
  &:focus {
    background-color: white;
    box-shadow: 0 0 2px 2px gray;
  }
}

.toggle_btn {
  cursor: pointer;
  width: 10vw;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  border: 1px solid black;
  background-color: #ededed9d;
  letter-spacing: 0.5px;
  margin-left: 1vw;
  padding: 10px;
  padding-left: 10px;
  font-family: "Gilroy-SemiBold";
  box-sizing: border-box;
  transition: 400ms ease-out;
  &:hover {
    box-shadow: 0 0 1px 1px gray;
  }
}

.auction_product_container {
  width: 80vw;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, calc(78.5 / 4 * 1vw));
  gap: 1vw;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

@media (max-width: 1100px) {
  .auction_product_container {
    width: 100vw;
    grid-template-columns: repeat(2, calc(78.5 / 2 * 1vw));
  }
  .form {
    width: 90vw;
  }
}

@media (max-width: 750px) {
  .auction_product_container {
    box-sizing: border-box;
    width: 98vw;
    margin: auto;
    grid-template-columns: repeat(1, 80vw);
    gap: 3vh;
    justify-content: center;
    align-items: center;
  }
  .form {
    display: none;
  }
}

.products__header {
  width: 100vw;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
}

.products__header__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;

  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.products__header__text {
  font-size: 1rem;
  margin-bottom: 0.4vh;
  margin-top: 3vh;
  display: flex;
  justify-content: flex-start;
  flex: 1;

    @media (max-width: 900px) {
      font-size: 0.8rem;
    }
}

.dropdown_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1vw;
  margin-top: 3vh;
  margin-bottom: 0.4vh;
  width: 40vw;

  select {
    font-size: 0.8rem;
  }

  @media (max-width: 1024px) {
    width: 50vw;
  }

  @media (max-width: 900px) {
    width: 60vw;

  }

    @media (max-width: 750px) {
      flex-direction: column;
      width: 80vw;
      margin-bottom: 2vh;
    }
}

.dropdown_inner_container {
  //border: 2px solid #ededed9d;
  //border-radius: 20px;
  width: 80%;
  transition: 300ms;
  //padding: 10px;
  box-sizing: border-box;


  &:focus {
    background-color: white;
    box-shadow: 0 0 2px 2px gray;
  }
}

.sort_dropdown,
.artist_filter_dropdown {
  width: 100%;
  max-width: 500px;
  outline: none;
  //background-color: #ededed9d;
  font-family: "Gilroy-Light";
  //padding: 10px;
  box-sizing: border-box;
  border: none;
  background-color: transparent;

  max-height: 200px;
  //overflow-y: scroll;

  select {
    color: #bc2832;
  }

}

.load_more_button {
  cursor: pointer;
  width: auto;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  background-color: white;
  border-style: none;
  font-family: "Gilroy-Light";
  box-sizing: border-box;
  transition: 400ms ease-out;
  &:hover {
   font-family: "Gilroy-SemiBold";
  }
  
  @media (max-width: 750px) {
    margin-top: 3vw;
    font-size: .9rem;
  }
}

.spin_container {
  height: 20vh;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

