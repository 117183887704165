.timer {
    box-sizing: border-box;
    width: 80%;
    height: 5vh;
    background-color: white;
    //border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: auto;
    margin-top: 1vh;
    margin-bottom: 2vh;
    font-size: 18px;
    letter-spacing: 0.5px;
}