@import "../styling/Animations.scss";

.artist_details {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 6vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  overflow-x: unset;
  height: auto;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.section1 {
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
  }
}

.image_container {
  width: 32vw;
  max-height: 50vh;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;

  @media (max-width: 1200px) {
    width: 32vw;
    height: auto;
    padding-right: 0;
  }

  @media (max-width: 750px) {
    width: 80vw;
    height: auto;
    padding-bottom: 2vh;
    padding-right: 5vw;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;

  @media (max-width: 1200px) {
    height: auto;
    width: 100%;
  }

  @media (max-width: 750px) {
    height: auto;
    width: 100%;
  }
}

.text_container {
  box-sizing: border-box;
  width: 48vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  letter-spacing: 1px;
  padding-left: 5vw;
  padding-right: 5vw;
  font-size: 20px;

  @media (max-width: 1200px) {
    width: 48vw;
    height: auto;
    padding: 15px;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 15px;
  }

  @media (max-width: 750px) {
    width: 80vw;
    height: auto;
    padding: 15px;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 13px;
  }
}

.artist_name {
  font-family: "Gilroy-SemiBold";
  font-size: 30px;
}

.hr {
  width: 100%;
  border: none;
  height: 1px;
  background-color: #dedad9;
  margin: 30px 0;
}

.section2 {
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.story_container {
  width: auto;
  height: auto;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-left: 5vw;
  padding-right: 5vw;
  letter-spacing: 1px;
  font-size: 15px;

  @media (max-width: 1200px) {
    font-size: 13px;
  }

  @media (max-width: 750px) {
    font-size: 11px;
  }
}

.awards_container {
  width: auto;
  height: auto;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-left: 5vw;
  padding-right: 5vw;
}

.awards_header {
  color: #a11919;
  font-family: "Gilroy-Semibold";
  margin-bottom: 5px;
  margin-top: 10px;
}

.video_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 750px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.section3 {
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3vh;
  padding-right: 5vw;
  padding-left: 5vw;
}

.artwork_container {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, calc(69.25 / 4 * 1vw));
  gap: 0.25vw;
  justify-content: center;
  padding-right: 5vw;
  padding-left: 5vw;
  padding: 15px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, calc(68.5 / 3 * 1vw));
  }

  @media (max-width: 750px) {
    grid-template-columns: repeat(2, calc(68.5 / 2 * 1vw));
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, calc(68.5 / 1 * 1vw));
    gap: 2vh;
  }
}
