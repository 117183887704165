@import "../../styling/Animations.scss";

.product_card {
    height: 60vh;
    // width: 20vw;
    background-color: #ecececa8;
    //z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1%;
    border-radius: 10px;
    //box-shadow: 5px 5px 1px #dedede7e;
    @include fadeIn;
    position: relative;

}

.product_card__img {
    margin: 10px;
    min-height: 27%;
    max-height: 35%;
    width: auto;
    max-width: 100%;
    cursor: pointer;
}

.text_bold {
    font-family: "Gilroy-SemiBold";
    font-size: 15px;
    margin-bottom: 0.2vh;
}

.text_normal {
    margin-top: 0.8vh;
    font-size: 15px;
    text-align: center;
    margin-bottom: 5px;
    width: 80%;
    height: 5vh;
}

.product_card__btn_container {
    width: 80%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1vh;
}

.banner {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  border: 2px solid black;
  color: black;
  // border: none;
  font-family: 'Gilroy-SemiBold';
  cursor: pointer;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 1rem;
  letter-spacing: .02rem;
  transition: all .3s;
}

.bannerText {
  box-sizing: border-box;
  width: 80%;
  height: 5vh;
  background-color: white;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  margin-top: 1vh;
  margin-bottom: 2vh;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.product_card__btn_container__btn {
        width: 47%;
        height: 100%;
        border-radius: 20px;
        background-color: black;
        border: 2px solid white;
        color: white;
        // border: none;
        font-family: 'Gilroy-SemiBold';
        cursor: pointer;
        transition: 300ms;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.1);
        }
        // &:hover {
        //   background-color: #950101;
        //   color: white;
        // }
      
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        letter-spacing: .02rem;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
  /*
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: black;
          background-color: white;
          z-index: -2;
        }
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: darken(black, 15%);
          color: white;
          transition: all .3s;
          z-index: -1;
        }
        &:hover {
          color: #fff;
          &:before {
            width: 100%;
          }
        }

   */
      
}

.change {
  animation: moveDownSm 0.5s forwards ease-in;
}

@keyframes moveDownSm {
  from {
    opacity: 0;
    transform: translateY(-2vh);
  }
  to {
    opacity: 1;
    transform: translateY(0vh);
  }
}

.bidCount {
  position: absolute;
  letter-spacing: 2px;
  bottom: 5px;
  width: 80%;
  text-align: center;
}

.watchlistIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  border: 1px solid #d3d3d3;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  i{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i:hover {
    color: #f32f1e;
    fill: #f32f1e;
  }
}

.bid_options {
  height: 11vh;
  overflow-y: scroll;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  background-color: #f9f9f9;
  width: 80%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 2;
  transition: 400ms;
  flex-direction: column;
  padding: 0px;


  @include fadeIn;
  & .bid_option {
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
      background-color: #e9e9e9;
    }
  }
}

.confirm_buttons {
  width: 47%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1vh;
  gap: 10px;
}
