.blogContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.blogGrid {
  display: grid;
  gap: 20px;
  padding: 20px;
  width: 80vw;
  grid-template-columns: 1fr;
}


@media (min-width: 768px) {
  .blogGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .blogGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
