@import "../styling/Animations.scss";

.verification {
  box-sizing: border-box;
  // background: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10vh;
  width: 100vw;
  // position: absolute;
}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}


.modal {
  height: 60vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
//   justify-content: center;
  align-items: center;
  background-color: #dfdfdf98;
  border-radius: 15px;
  box-sizing: border-box;
  padding-top: 5vh;
  box-shadow: 0 0 5px 2.5px #9090908e;
  position: relative;
  @media (max-width: 768px) {
    width: 50vw;
  }

  @media (max-width: 450px) {
    width: 70vw;
  }
}


.modal__title {
    font-family: 'Gilroy-Black';
    font-size: 20px;
    color: black;
    margin-bottom: 5vh;
  }

.modal__message {
    text-align: center;
    font-size: 16px;
    color: black;
    margin-bottom: 2vh;
}

.span {
    position: absolute;
    bottom: 2vh;
    font-family: 'Gilroy-SemiBold';
    cursor: pointer;
    transition: 300ms;
    color: #2a2a2a;
    &:hover {
        color: black;
    }
}

.span2 {
    font-size: 14px;
    position: absolute;
    bottom: 6vh;
    color: #808080a9;
    transition: 300ms;
    color: gray;
}

.spin_container {
    position: absolute;
    top: 45%;
}