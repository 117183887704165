@import "../styling/Animations.scss";

.artists {
  box-sizing: border-box;
  // background: gray;
  display: flex;
  justify-content: center;
  padding-top: 3dvh;
  width: 100vw;
  flex-direction: column;
  overflow-x: unset;
  height: auto;
}

.artist_container {
  // background-color: red;
  width: 80vw;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, calc(78.5 / 5 * 1vw));
  gap: 0.25vw;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-bottom: 5vh;

}

.in {
  @include fadeIn;
}

.out {
  @include fadeOut;
}

.spin_container {
 height: 20vh;
 width: 80vw;
 display: flex;
 align-items: center;
 justify-content: center;
}

@media (max-width: 1200px) {
  .artist_container {
    width: 80vw;
    grid-template-columns: repeat(3, calc(78.5 / 3 * 1vw));
  }
}

@media (max-width: 750px) {
  .artist_container {
    width: 80vw;
    grid-template-columns: repeat(2, calc(78.5 / 2 * 1vw));
  }
  .form {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .artist_container {
    width: 80vw;
    grid-template-columns: repeat(1, calc(78.5 / 1 * 1vw));
    gap: 2vh;
  }
}

.load_more_container {
  width: 100vw;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
}

.load_more_button {
  cursor: pointer;
  width: auto;
  height: 5vh;
  border-radius: 10px;
  outline: none;
  background-color: white;
  border-style: none;
  font-family: "Gilroy-Light";
  box-sizing: border-box;
  transition: 400ms ease-out;
  &:hover {
    font-family: "Gilroy-SemiBold";
  }

  @media (max-width: 750px) {
    margin-top: 3vw;
    font-size: .9rem;
  }
}

.dropdown_container {
  display: flex;
  width: 80%;
  margin: auto;
  padding-bottom: 20px;
  justify-content: end;
}

.dropdown {
  border: 2px solid #ededed9d;
  border-radius: 20px;
  transition: 300ms;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;

  max-height: 200px;

  margin: 0 1vw;
  select {
    color: #bc2832;
  }
}