@import "../../styling/Animations.scss";

.product_card {
  height: 600px;
  // width: 20vw;
  background-color: #f6f6f6;
  //z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 1%;
  border-radius: 10px;
 // box-shadow: inset 0px 0px 1px 0.3px rgb(81 81 81 / 49%);
  @include fadeIn;
  position: relative;
}

.product_card__img {
  height: 40%;
  width: auto;
  margin: 10px 0;
  max-width: 80%;
  cursor: pointer;
  object-fit: contain;
}

.text_bold {
  font-family: "Gilroy-SemiBold";
  font-size: 1.15rem;
  margin-bottom: 5px;
}

.text_normal {
  font-size: 1rem;
  text-align: center;
  margin: 10px 0;
  width: 80%;
  height: auto;
}

.text_container {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: 1vh;

  p {
    margin: 0;
  }
}

.product_card__btn_container {
  width: 80%;
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;


}

.banner {
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: black;
  border: .2px solid white;
  color: white;
  // border: none;
  font-family: 'Gilroy-SemiBold';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  letter-spacing: .02rem;
}

.bannerText {
  box-sizing: border-box;
  width: 80%;
  height: 5vh;
  background-color: white;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  margin-top: 1vh;
  margin-bottom: 2vh;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.product_card__btn_container__btn {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: black;
  border: .2px solid white;
  // border: none;
  font-family: 'Gilroy-SemiBold';
  cursor: pointer;
  // &:hover {
  //   background-color: #950101;
  //   color: white;
  // }

  display: inline-block;
  color: white;
  font-size: 0.9rem;
  letter-spacing: .02rem;
  //transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }

  /*
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
    background-color: white;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(black, 15%);
    color: white;
    transition: all .3s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }

   */

}

.change {
  animation: moveDownSm 0.5s forwards ease-in;
}

@keyframes moveDownSm {
  from {
    opacity: 0;
    transform: translateY(-2vh);
  }
  to {
    opacity: 1;
    transform: translateY(0vh);
  }
}

.bidCount {
  position: absolute;
  letter-spacing: 2px;
  bottom: 5px;
  width: 80%;
  text-align: center;
}